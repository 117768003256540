import React from 'react';
import Main from './Main';
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Route path="/:slug" exact render={props => <Main merchant={props.match.params.slug} />} />
      </Router>
    )
  }
}

export default App;
