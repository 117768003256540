// TODO: Fix how amounts are handled:
// * Multiplying by 100 sucks
// * Active amount tracking sucks

import React, { Component } from 'react';
import './Main.css';
import profilePic from '../resources/armaan-profile.png';
import {StripeProvider, Elements} from 'react-stripe-elements';
import PaymentRequest from './PaymentRequest.js';
import AmountButton from './AmountButton.js';

class Main extends Component {
  constructor(props) {
    super(props);

    this.renderMain = this.renderMain.bind(this);

    this.state = {
      loaded: false
    }
  }

  active(amount) {
    this.setState({ activeAmount: Number(amount) });
  }

  componentDidMount() {
    const endpointUrl = '/.netlify/functions/get-merchant';

    fetch(`${endpointUrl}?slug=${this.props.merchant}`)
      .then(response => response.json())
      .then(data => this.setState({ merchant: data, loaded: true, activeAmount: data.tip_amounts[1] * 100 }))
  }

  render() {
    return (
      <div>
        {this.state.loaded ? this.renderMain() : <div>Loading...</div>}
      </div>
    )
  }

  renderMain() {
    return (
      <StripeProvider apiKey="pk_live_SwwVUt9OuRYvSNLv1Z9uEmjS">
        <div className="App">
          <div className="mdl-layout mdl-js-layout main">
            <main className="mdl-layout__content">
              <div className="mdl-grid">
                <div className="mdl-cell mdl-cell--8-col">
                  <div id="pay-square" className="my-card mdl-card mdl-shadow--2dp">
                    <div className="mdl-card__media">
                      <img className="avatar" src={profilePic} width="100" height="100" border="0" alt="" style={{padding: "20px"}} />
                    </div>
                    <div id="user-info" className="mdl-card__supporting-text">
                      <div id="user-name">
                        {this.state.merchant.name}
                      </div>
                      <div id="user-description">
                        {this.state.merchant.description}
                      </div>
                    </div>
                    <div id="payment-section">
                      <div className="mdl-card__actions mdl-card--border">
                        <div>
                          <div className="mdl-card__supporting-text">
                            How much would you like to tip?
                          </div>
                          <div className="mdl-button-group">
                            {this.state.merchant.tip_amounts.map(a => <AmountButton key={a} amount={100 * a} active={this.state.activeAmount === 100 * a} onClick={() => this.active(100 * a)} />)}
                          </div>
                        </div>
                        <div className="payment-request-button">
                          <Elements>
                            <PaymentRequest amount={this.state.activeAmount} venmoHandle={this.state.merchant.venmo} />
                          </Elements>
                        </div>
                      </div>
                    </div>
                    <div id="post-payment-section" className="my-card mdl-card__actions mdl-card--border" style={{ display: "none" }}>
                      <div id="success-section" className="mdl-card__supporting-text">
                        <div>
                          <svg id="success-check" width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                            <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#FFAEC8" fill="none"></circle>
                            <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#FF407F" fill="none"></path>
                          </svg>
                        </div>
                        <p id="success-message">
                          Thank you!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </StripeProvider>
    );
  }
}

export default Main;
