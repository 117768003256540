import React, { Component } from 'react';
import classnames from 'classnames';

class AmountButton extends Component {
  render() {
    const buttonClasses = ["mdl-button", "mdl-js-button", "mdl-js-ripple-effect", "mdl-button--accent"];

    return (
      <button
        className={classnames(buttonClasses, { "mdl-button--raised": this.props.active })}
        onClick={this.props.onClick}>

        {`$${this.props.amount/100}`}
      </button>
    )
  }
}

export default AmountButton;
