import React from 'react';
import {injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';
import venmoLogo from '../images/venmo_logo_white.png';

class PaymentRequest extends React.Component {
  constructor(props) {
    super(props);

    this.venmo = this.venmo.bind(this);
    this.charge = this.charge.bind(this);

    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Tip Total',
        amount: 200,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentRequest.on('token', this.charge);

    paymentRequest.canMakePayment().then((result) => {
      this.setState({canMakePayment: !!result});
    });

    this.state = {
      canMakePayment: false,
      paymentRequest,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.state.paymentRequest.update({ total: { label: 'Tip Total', amount: this.props.amount }});
  }

  venmo() {
    window.location = `venmo://paycharge?txn=pay&recipients=${this.props.venmoHandle}&amount=${this.props.amount / 100}&note=Kudos!`
    return false;
  }

  render() {
    return this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
      />
    ) : (
      <div id="payment-fallback-button">
        <input type="image" alt="Pay with Venmo!" onClick={this.venmo} src={venmoLogo} style={{ height: "20px", backgroundColor: "#5294C7", padding: "10px 50px", borderRadius: "20px", marginTop: "10px"}} />
      </div>
    );
  }

  charge(ev) {
    const endpointUrl = '/.netlify/functions/charge';
    // Send the token to your server to charge it!
    fetch(endpointUrl, {
      method: 'POST',
      body: JSON.stringify({amount: this.props.amount, token: ev.token.id}),
      headers: {'content-type': 'application/json'},
    })
    .then(function(response) {
      if (response.ok) {
        // Report to the browser that the payment was successful, prompting
        // it to close the browser payment interface.
        ev.complete('success');
        document.getElementById('payment-section').style.display = 'none'
        document.getElementById('post-payment-section').style.display = 'block'

      } else {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      }
    });
  }
}

export default injectStripe(PaymentRequest);
